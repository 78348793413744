.firstData {
  padding: 1.428571rem 0 0.571429rem;
}

.firstDataFeed {
  display: block;
  overflow: hidden;
}

.firstDataFeedPicBox {
  width: 4.285714rem;
  height: 5.714286rem;
  float: left;
  margin-right: 0.714286rem;
  overflow: hidden;
}

.firstDataFeedPic {
  width: 4.285714rem;
  height: 5.714286rem;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.firstDataFeedName {
  font-family: PingFangSC-Medium;
  font-weight: bold;
  font-size: 1.428571rem;
  line-height: 2rem;
  color: #212223;
}

.firstDataFeedBref {
  padding-top: 0.357143rem;
  font-family: PingFangSC-Regular;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  color: #212223;
}

.firstDataFeed:hover .firstDataFeedName {
  color: #F54343;
}

.firstDataFeed:hover .firstDataFeedBref {
  color: #F54343;
}

.firstDataFeed:hover .firstDataFeedPic {
  -webkit-transform: scale(1.04, 1.04);
  -ms-transform: scale(1.04, 1.04);
  transform: scale(1.04, 1.04);
}

.firstDataInfo {
  display: block;
  width: 16.285714rem;
  padding: 0.714286rem 2.571429rem 0.857143rem;
  background: #F7F7F7;
  position: relative;
  margin-top: 0.571429rem;
}

.firstDataMarkL {
  position: absolute;
  top: 0.857143rem;
  left: 0.714286rem;
  width: 1.142857rem;
  height: 1rem;
  background: url(https://x0.ifengimg.com/ucms/2023_26/9A673CA9EBEDFA3885107A5018A5509A8BF90DC2_size1_w48_h42.png) no-repeat 0 0;
  background-size: contain;
}

.firstDataMarkR {
  position: absolute;
  bottom: 0.857143rem;
  right: 0.714286rem;
  width: 1.142857rem;
  height: 1rem;
  background: url(https://x0.ifengimg.com/ucms/2023_26/E9FDA8C6AFE7F6BAB04BFE32E8BE96D01C161E86_size1_w48_h42.png) no-repeat 0 0;
  background-size: contain;
}

.firstDataInfoTitle {
  font-family: PingFangSC-SNaNpxibold;
  font-weight: bold;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  color: #212223;
}

.firstDataInfoBref {
  padding-top: 0.428571rem;
  font-family: PingFangSC-Regular;
  font-size: 1rem;
  line-height: 1.428571rem;
  color: #757575;
}

.firstDataInfo:hover .firstDataInfoTitle {
  color: #F54343;
}

.firstDataInfo:hover .firstDataInfoBref {
  color: #F54343;
}

.insightItem {
  display: block;
  font-family: PingFangSC-Regular;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  padding: 0.714286rem 0;
  color: #212223;
}

.insightMore {
  display: block;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 1rem;
  color: #84848A;
  height: 2.571429rem;
  line-height: 2.571429rem;
  text-align: center;
  background: #f0f0f0;
}