.liveItem {
  padding: 0.714286rem 0;
  overflow: hidden;
  display: block;
}

.liveTime {
  width: 5.571429rem;
  float: left;
  font-family: PingFangSC-Medium;
  font-weight: bold;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  color: #212223;
}

.liveText {
  width: 20.142857rem;
  float: left;
  height: 3.142857rem;
  font-family: PingFangSC-Regular;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  color: #212223;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*行数*/
  -webkit-box-orient: vertical;
}