.main_nav {
  width: 100rem;
  margin: 0 auto;
  font-family: PingFangSC-Regular, PingFang SC;
  position: relative;
  z-index: 999;
  background: #fff;
}

.main_nav ul {
  width: auto;
}

.search_btn > div > div {
  width: auto;
}

.user {
  float: right;
  text-align: right;
  height: 2.714286rem;
  padding-top: 0.5rem;
}

.box {
  width: 100rem;
  height: 9rem;
  float: right;
  text-align: center;
  line-height: 9rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  margin: 0;
}

.ad {
  width: 100rem;
  /* height: 126px; */
  margin: 0 auto;
}

.search {
  width: 100rem;
  margin: 0 auto;
  height: 1.857143rem;
  padding-top: 2rem;
  padding-bottom: 0.714286rem;
}

.search_btn {
  float: right;
}

.logo {
  float: left;
  font-size: 0.857143rem;
}

.logo a img {
  position: relative;
  left: 0;
  top: 0;
  right: 25.785714rem;
  float: left;
}