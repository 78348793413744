.moduleHead {
  height: 2.571429rem;
  padding-top: 1.642857rem;
  border-bottom: 1px solid rgba(245, 67, 67, .3);
  overflow: hidden;
}

:global(.hairlines) .moduleHead {
  border-bottom: 0.5px solid rgba(245, 67, 67, .3);
}

.title {
  position: relative;
  top: 1px;
  float: left;
  font-family: PingFangSC-Medium;
  font-weight: bold;
  font-size: 1.428571rem;
  line-height: 2rem;
  color: #212223;
  padding-bottom: 0.357143rem;
  border-bottom: 0.285714rem solid #F54343;
}

:global(.hairlines) .title {
  top: 0.5px;
}

.subTitle {
  float: left;
  padding: 0.285714rem 0 0 0.285714rem;
  font-family: PingFangSC-Regular;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  color: #9E9E9E;
}

.more {
  float: right;
  margin: 0.357143rem 1.071429rem 0 0;
  padding-right: 0.785714rem;
  font-family: PingFangSC-Regular;
  font-size: 1rem;
  color: #666666 !important;
  line-height: 1.428571rem;
  text-decoration: none;
  background: url(https://x0.ifengimg.com/ucms/2023_25/D014021AFFA8CC4379993653828C0F2F8003650A_size1_w24_h60.png) no-repeat right center/ 0.571429rem 1.428571rem;
}

.more:hover {
  color: #F54343;
}