.stocks {
  /* margin-top: 20px;*/
}

.columnBdCanvasBox {
  position: relative;
  /* height: 260px;*/
  margin-bottom: 1.428571rem;
}

.columnBdCanvas {
  height: 17.142857rem;
}

.columnBdBox {
  border-radius: 0.142857rem 0.142857rem 0 0;
}

.columnBdTable {
  width: 100%;
  text-align: left;
  border-radius: 0.142857rem 0.142857rem 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

.columnBdTableActive {
  background: #f5f5fa;
}

.columnBdTableTr {
  cursor: pointer;
}

.columnBdTableTd {
  text-align: center;
  padding: 0.571429rem;
  color: #333;
  font-size: 1rem;
}

.columnBdTableTd a {
  color: #333;
}

.columnBdTableRed {
  color: #e84f42;
}

.columnBdTableGreen {
  color: #35ba97;
}

.columnBdTableGray {
  color: #333;
}

.columnBdStartTime {
  position: absolute;
  bottom: 0.142857rem;
  left: 0;
  font-size: 0.857143rem;
  color: #999;
}

.columnBdEndTime {
  position: absolute;
  bottom: 0.142857rem;
  right: 0;
  font-size: 0.857143rem;
  color: #999;
}