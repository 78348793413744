.wechatBox {
  padding: 1.428571rem 0 0;
}

.wechatItem {
  display: block;
  overflow: hidden;
  margin-bottom: 2.142857rem;
}

.wechatItem:last-child {
  margin-bottom: 0;
}

.wechatQrcode {
  float: left;
  width: 5rem;
  height: 5rem;
  margin-right: 0.714286rem;
}

.wechatText {
  margin-top: 0.714286rem;
}

.wechatTitle {
  font-family: PingFangSC-Medium;
  font-weight: bold;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  color: #212223;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wechatBref {
  padding-top: 0.571429rem;
  font-family: PingFangSC-Regular;
  font-size: 1rem;
  line-height: 1.428571rem;
  color: #757575;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}