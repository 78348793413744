.col_box {
  background: #f54343;
  width: 100%;
  margin: 0 auto;
  height: 2.857143rem;
}

.col_navlist {
  width: 100rem;
  background: #f54343;
  margin: 0 auto;
  height: 2.857143rem;
}

.col_navlist ul {
  height: 2.857143rem;
  overflow: hidden;
}

.col_navlist ul li {
  font-size: 1.142857rem;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
  height: 2.857143rem;
  line-height: 2.857143rem;
  float: left;
}

.col_navlist ul li a {
  display: inline-block;
  height: 2.857143rem;
  padding: 0 2.142857rem;
  color: #fff;
}

.col_navlist ul li:hover {
  background-color: #f76969;
  cursor: pointer;
}

.col_navlist ul a:hover {
  text-decoration: none;
  cursor: pointer;
}

.pa a {
  color: #ffc200;
}