.globalContent {
  padding: 1.142857rem 0 0.285714rem;
}

.globalItem {
  padding-bottom: 0.857143rem;
}

.globalBoldText {
  display: block;
  padding-bottom: 0.714286rem;
  font-family: PingFangSC-SNaNpxibold;
  font-weight: bold;
  font-size: 1.428571rem;
  line-height: 2rem;
  color: #212223;
  text-decoration: none;
}

.globalThinText {
  display: block;
  padding-bottom: 0.714286rem;
  font-size: 1.142857rem;
  color: #212223;
  line-height: 1.857143rem;
  text-decoration: none;
}