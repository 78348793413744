.newsFeed {
  padding: 1.428571rem 0;
  display: block;
  overflow: hidden;
}

.newsFeedImgBox {
  width: 12.5rem;
  height: 7rem;
  float: left;
  margin-right: 1.428571rem;
  overflow: hidden;
}

.newsFeedImg {
  width: 12.5rem;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.newsFeed:hover .newsFeedImg {
  -webkit-transform: scale(1.04, 1.04);
  -ms-transform: scale(1.04, 1.04);
  transform: scale(1.04, 1.04);
}

.newsFeedTitle {
  height: 3.571429rem;
  font-family: PingFangSC-SNaNpxibold;
  font-weight: bold;
  font-size: 1.285714rem;
  line-height: 1.785714rem;
  text-align: justify;
  color: #212223;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*行数*/
  -webkit-box-orient: vertical;
}

.newsFeedInfo {
  padding-top: 2rem;
}

.newsFeedTime,
.newsFeedComment {
  font-family: PingFangSC-Regular;
  font-size: 1rem;
  color: #757575;
}

.newsFeedComment {
  padding-left: 0.714286rem;
}

.newsFeed:hover .newsFeedTitle {
  color: #F54343;
}

.newsBanner {
  padding: 0.714286rem 0;
}