.hotListBox {
  padding: 0.714286rem 0 0.428571rem;
}

.hotListItem {
  overflow: hidden;
  padding-bottom: 0.214286rem;
  border-bottom: 0.214286rem solid #F7F7F7;
}

.hotListItem:last-child {
  border: none;
}

.hotListTopic {
  display: block;
  overflow: hidden;
  border-bottom: 1px solid #EEE;
}

:global(.hairlines) .hotListTopic {
  border-bottom: 0.5px solid #EEE;
}

.hotListTopicTitle {
  float: left;
  width: 16.571429rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: PingFangSC-SNaNpxibold;
  font-weight: bold;
  font-size: 1.142857rem;
  line-height: 2.714286rem;
  color: #212223;
}

.hotListTopicTitle:hover {
  color: #F54343;
}

.hotListTopicNum {
  float: right;
  font-family: PingFangSC-Regular;
  font-size: 1rem;
  line-height: 2.714286rem;
  padding-right: 0.785714rem;
  color: #84848A;
  background: url(https://x0.ifengimg.com/ucms/2023_25/D014021AFFA8CC4379993653828C0F2F8003650A_size1_w24_h60.png) no-repeat right center;
  background-size: 0.571429rem 1.428571rem;
}

.hotListFeed {
  padding: 0.714286rem 0;
  display: block;
}

.hotListFeedPicBox {
  width: 8.571429rem;
  height: 4.785714rem;
  margin-right: 0.714286rem;
  float: left;
  overflow: hidden;
}

.hotListFeedPic {
  width: 8.571429rem;
  height: 4.785714rem;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.hotListFeedTitle {
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  color: #212223;
  text-align: justify;
}

.hotListFeed:hover .hotListFeedTitle {
  color: #F54343;
}

.hotListFeed:hover .hotListFeedPic {
  -webkit-transform: scale(1.04, 1.04);
  -ms-transform: scale(1.04, 1.04);
  transform: scale(1.04, 1.04);
}

.hotListMore {
  display: block;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 1rem;
  color: #84848A;
  height: 2.571429rem;
  line-height: 2.571429rem;
  text-align: center;
  background: #f0f0f0;
}