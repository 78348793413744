.featuredBox {
  padding-top: 0.714286rem;
}

.featuredLink {
  display: block;
  width: 21.428571rem;
  overflow: hidden;
  margin-bottom: 1.428571rem;
}

.featuredLink:last-child {
  margin-bottom: 0;
}

.featuredTitle {
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 1.285714rem;
  line-height: 1.785714rem;
  color: #212223;
}

.featuredPicBox {
  width: 21.428571rem;
  margin-top: 0.285714rem;
  display: block;
  overflow: hidden;
}

.featuredPic {
  width: 21.428571rem;
  display: block;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.featuredLink:hover .featuredTitle {
  color: #F54343;
}

.featuredLink:hover .featuredPic {
  -webkit-transform: scale(1.04, 1.04);
  -ms-transform: scale(1.04, 1.04);
  transform: scale(1.04, 1.04);
}