.clickRankBox {
  padding-top: 0.285714rem;
}

.clickRankItem {
  height: 2.714286rem;
  display: block;
  overflow: hidden;
}

.clickRankNum {
  width: 1.142857rem;
  height: 1.142857rem;
  line-height: 1.142857rem;
  text-align: center;
  background: #C5C5C5;
  float: left;
  font-family: PingFangSC-Medium;
  font-weight: bold;
  font-size: 0.857143rem;
  color: #FFFFFF;
  margin: 0.785714rem 0.428571rem 0 0;
}

.clickRankTitle {
  width: 19.857143rem;
  float: left;
  font-family: PingFangSC-Regular;
  font-size: 1.142857rem;
  line-height: 2.714286rem;
  color: #212223;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clickRankItem:nth-child(1) .clickRankNum {
  background: #F54343;
}

.clickRankItem:nth-child(2) .clickRankNum {
  background: #F77B32;
}

.clickRankItem:nth-child(3) .clickRankNum {
  background: #F8C945;
}

.clickRankItem:hover .clickRankTitle {
  color: #F54343;
}