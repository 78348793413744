.stockList {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 2.142857rem;
}

.marketItem {
  width: 9.285714rem;
  margin-bottom: 0.857143rem;
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.714286rem;
  border-radius: 1.142857rem;
  height: 7.428571rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.marketItemBox {
  width: 100%;
}

.marketRed {
  background: #fef4f3;
}

.marketGreen {
  background: #f4faf6;
}

.marketGray {
  background: #eee;
}

.marketName {
  font-size: 1.142857rem;
  text-align: center;
  color: #333;
}

.marketPrice {
  font-size: 1.285714rem;
  font-weight: 700;
  text-align: center;
  color: #333;
  padding: 0.285714rem 0;
}

.marketRange {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 0.571429rem;
  font-size: 1rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #333;
}

.marketRed .marketPrice,
.marketRed .marketRange {
  color: #e84f42;
}

.marketGreen .marketPrice,
.marketGreen .marketRange {
  color: #35ba97;
}