.content {
  width: 100rem;
  overflow: hidden;
  margin: 0 auto;
}

.contentLeft {
  width: 45.714286rem;
  margin-right: 4.285714rem;
  float: left;
}

.contentCenter {
  width: 25.714286rem;
  margin-right: 2.857143rem;
  float: left;
}

.contentRight {
  width: 21.428571rem;
  float: left;
}

.rightAdBox {
  margin-top: 1.428571rem;
}