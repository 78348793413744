.moduleFeed {
  padding-bottom: 0.714286rem;
}

.moduleFeedFirst {
  display: block;
  margin-top: 1.428571rem;
  overflow: hidden;
}

.moduleFeedPicBox {
  width: 12.5rem;
  height: 7rem;
  float: left;
  margin-right: 0.714286rem;
  overflow: hidden;
}

.moduleFeedPic {
  width: 12.5rem;
  height: 7rem;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.moduleFeedFirstText {
  padding-top: 0.714286rem;
}

.moduleFeedFirstTitle {
  font-family: PingFangSC-Medium;
  font-weight: bold;
  font-size: 1.428571rem;
  line-height: 2rem;
  color: #212223;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.moduleFeedFirstBref {
  font-family: PingFangSC-Regular;
  font-size: 1rem;
  line-height: 1.428571rem;
  color: #757575;
  padding-top: 0.714286rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*行数*/
  -webkit-box-orient: vertical;
}

.moduleFeedFirst:hover .moduleFeedFirstTitle {
  color: #F54343;
}

.moduleFeedFirst:hover .moduleFeedFirstBref {
  color: #F54343;
}

.moduleFeedFirst:hover .moduleFeedPic {
  -webkit-transform: scale(1.04, 1.04);
  -ms-transform: scale(1.04, 1.04);
  transform: scale(1.04, 1.04);
}

.moduleFeedTitle {
  font-family: PingFangSC-Regular;
  display: block;
  padding-top: 1.428571rem;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  color: #212223;
  font-weight: normal;
}

.moduleFeedTitleBold {
  font-family: PingFangSC-Medium;
  font-weight: bold;
}

.moduleFeedTitle:hover {
  color: #F54343;
}