.focusBox {
  overflow: hidden;
}

.focusPic {
  margin-top: 1.428571rem;
  width: 21.428571rem;
  overflow: hidden;
  display: block;
}

.focusImg {
  display: block;
  width: 21.428571rem;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.focusPic:hover .focusImg {
  -webkit-transform: scale(1.04, 1.04);
  -ms-transform: scale(1.04, 1.04);
  transform: scale(1.04, 1.04);
}

.focusText {
  display: block;
  position: relative;
  padding-left: 1.142857rem;
  line-height: 3rem;
  height: 3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: PingFangSC-Regular;
  font-size: 1.142857rem;
  color: #212223;
}

.focusText::after {
  content: '';
  position: absolute;
  top: 1.285714rem;
  left: 0;
  width: 0.428571rem;
  height: 0.428571rem;
  background: #D9D9D9;
}