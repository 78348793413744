.hotspotsList {
  padding-top: 0.714286rem;
}

.hotspotsItem {
  display: block;
  padding-top: 1.428571rem;
  font-family: PingFangSC-Regular;
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  color: #212223;
}